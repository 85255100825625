<template>
  <div style="padding: 0 20px">
    <div v-if="selectedSection == 'gameCheck'" style="height: 100%">
      <div style="margin: 20px 0; text-align: center; font-weight: 600">
        How much will be remaining?
      </div>
      <AppDashboardWallet
        v-if="fromGoalOrStore == 'goal'"
        wallet="Spend"
        :balance="formatPrice(remainingMoney)"
        style="margin-bottom: 10px"
      />
      <AppDashboardWallet
        v-else
        wallet="Spend"
        :balance="formatPrice(getSpendWalletBalance)"
        style="margin-bottom: 10px"
      />
      <AppCostWallet :balance="formatPrice(checkoutGame.productPrice)" />
      <div style="width: 80%; margin: 0 auto; margin-top: 30px">
        <div>Your answer is:</div>
        <AppInput
          v-model="gameResponse"
          type="number"
          @input="validateButton"
        />
      </div>
      <div class="mt-auto">
        <AppButton
          text="Answer"
          :color="answerColor"
          :mini="true"
          class="rounded-[100px] py-3 px-12 text-white text-sm font-bold mt-8 block"
          style="width: 80%; margin: 0 auto"
          @click="verifyAnswer"
        />
      </div>
    </div>
    <div
      v-if="selectedSection == 'bonusPoints'"
      style="
        margin-top: 20px;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        color: var(--main);
      "
    >
      <div v-if="this.currentPointsNumber > 0">Correct!</div>
      <div v-if="this.currentPointsNumber > 0">
        +{{ currentPointsNumber }} Sonik Points
      </div>

      <div v-if="this.currentPointsNumber == 0">Incorrect!</div>
      <div v-if="this.currentPointsNumber == 0">No Sonik Points</div>
      <AppButton
        text="Go to checkout"
        class="bg-[#58C1E1] rounded-[100px] py-3 px-12 text-white text-sm font-bold"
        color="blue"
        :mini="true"
        style="
          width: 90%;
          position: fixed;
          bottom: 70px;
          left: 0;
          right: 0;
          margin: 0 auto;
        "
        @click="openCheckout"
      />
    </div>
  </div>
</template>

<script>
  import AppButton from "../../components/AppButton";
  // import AppErrorMessage from "@/components/AppErrorMessage";
  import AppDashboardWallet from "../../components/AppDashboardWallet";
  import AppCostWallet from "../../components/AppCostWallet";
  import AppInput from "../../components/AppInput";

  import { mapActions } from "vuex";
  import { mapGetters } from "vuex";

  import config from "../../config";

  export default {
    components: {
      AppButton,
      // AppErrorMessage,
      AppDashboardWallet,
      AppCostWallet,
      AppInput,
    },
    props: {
      name: {
        type: String,
        default: "",
      },
      description: {
        type: String,
        default: "",
      },
      defaultPicture: {
        type: String,
        default: "",
      },

      productId: {
        type: Number,
        default: 0,
      },
      productPrice: {
        // type: Number,
        // default: 0
      },
      fromGoalOrStore: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        currentPointsNumber: 0,
        selectedSection: "gameCheck",
        addresses: [],
        selectedAddressId: null,
        selected: [],
        error: "",
        buttonColor: null,
        config: [],
        gameResponse: null,
        answerColor: null,
        pointsNumber: 0,
      };
    },
    computed: {
      ...mapGetters([
        "getSpendWalletBalance",
        "getSpendWalletAndGoalsBalance",
        "getDeliveryAddresses",
        "getLastOrder",
      ]),
      remainingMoney() {
        return Number(this.getSpendWalletBalance).toFixed(2);
      },
      checkoutGame() {
        return JSON.parse(localStorage.getItem("checkoutGame"));
      },
    },
    created() {
      const user = {
        userId: localStorage.getItem("children_user_id"),
        token: localStorage.getItem("children_token"),
        family_id_check: localStorage.getItem("children_family_id"),
      };
      this.fetchProfile();
      this.setupUser(user);
      this.config = config;
      this.addresses = this.getDeliveryAddresses;
      this.addresses.forEach((address) => {
        address.selected = null;
      });

      // console.log(this.getLastOrder);
      // console.log(this.getDeliveryAddresses);
    },
    methods: {
      ...mapActions([
        "sendOrder",
        "fetchUser",
        "addPoints",
        "fetchProfile",
        "setupUser",
      ]),
      resolveImage(src) {
        return config.HOST + src;
      },
      selectAddress(addressId, index) {
        this.selectedAddressId = addressId;
        this.buttonColor = "blue";
        this.addresses.forEach((address) => {
          if (address.id == addressId) {
            this.addresses[index].selected = "active-address";
          } else {
            address.selected = null;
          }
        });
      },
      openCheckout() {
        const productObj = {
          selectedSection: "checkout",
          fromGoalOrStore: this.checkoutGame.from,
          from: this.checkoutGame.from,
          name: this.checkoutGame.name,
          description: this.checkoutGame.description,
          productPrice: Number(this.checkoutGame.productPrice).toFixed(2),
          defaultPicture: this.checkoutGame.defaultPicture,
          productId: this.checkoutGame.productId,
          goalId: this.checkoutGame.goalId,
        };

        localStorage.setItem("checkout", JSON.stringify(productObj));
        // console.log("from checkout game", productObj);

        if (this.checkoutGame.from == "wishlist") {
          this.$router.replace({
            name: "children-checkout_wishlist",
          });
        } else if (this.checkoutGame.from == "shop") {
          this.$router.replace({
            name: "children-checkout_shop",
          });
        } else if (this.checkoutGame.from == "goals") {
          this.$router.replace({
            name: "children-checkout_goal",
          });
        }
      },
      addOrder() {
        console.log(this.checkoutGame);
        if (this.selectedAddressId == null) {
          this.error = "Invalid address!";
        } else {
          var details = {
            delivery_address_id: this.selectedAddressId,
            staus: "paid",
            product_id: this.checkoutGame.productId,
            quantity: 1,
            by_from: this.checkoutGame.fromGoalOrStore,
          };
          this.sendOrder(details);
          this.error = "";
          this.selectedSection = "yourReceipt";
        }
      },
      validateButton() {
        if (this.gameResponse.length > 0) {
          this.answerColor = "blue";
        } else {
          this.answerColor = null;
        }
      },
      verifyAnswer() {
        if (this.gameResponse.length > 0) {
          // console.log("raspunsul meu");
          // console.log(this.gameResponse);
          // console.log("raspuns corect");
          // console.log(
          //   this.formatPrice(
          //     Math.max(this.getSpendWalletBalance, this.checkoutGame.productPrice) -
          //     Math.min(this.getSpendWalletBalance, this.checkoutGame.productPrice)
          //   )
          // );
          if (
            this.gameResponse ==
            Math.max(
              this.getSpendWalletBalance,
              this.checkoutGame.productPrice
            ) -
              Math.min(
                this.getSpendWalletBalance,
                this.checkoutGame.productPrice
              )
          ) {
            this.selectedSection = "bonusPoints";
            this.currentPointsNumber = (
              this.checkoutGame.productPrice / 2
            ).toFixed(2);
            // console.log(this.currentPointsNumber);

            const finalBonusPoints =
              Number(this.$store.state.currentOrderSonikPoints) +
              Number(this.currentPointsNumber);

            this.$store.state.currentOrderSonikPoints = finalBonusPoints;

            // console.log(this.$store.state.currentOrderSonikPoints);
            //   let payload = {
            //     points: this.currentPointsNumber,
            //     order_id: this.$store.state.lastOrderId,
            //     game_nr: 2,
            //   };
            //   this.addPoints(payload);
          } else {
            this.selectedSection = "bonusPoints";
            this.currentPointsNumber = 0;
          }
        } else {
          this.answerColor = null;
          this.gameResponse = "";
        }

        // console.log(this.gameResponse);

        // this.fetchUser();
      },
      closeModal() {
        if (this.fromGoalOrStore == "goal") {
          location.reload();
        }
        this.$emit("closeModal", true);

        this.fetchUser();
      },
    },
  };
</script>
